.loading {
    overflow: hidden;
}

.loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 500px;
    top: 0;
    left: -500px;
    border-top: .3rem solid rgba(51, 182, 229, .8);
    animation: loading-progress 1.2s ease-in-out infinite;
}

@keyframes loading-progress {
    0% {
        left: -500px;
    }

    100% {
        left: 100%;
    }
}