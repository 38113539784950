body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==) !important;
  padding-top: 75px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#userBar {
  margin: auto 0px;
}

.switch-group span {
  padding: .375rem .75rem;
  text-transform: none;
}

#footer {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.backBtn {
  display: flex;
  align-content: center;
}

.backBtn i {
  background-color: #f6f6f6;
  border-radius: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.backBtn i:hover {
  background-color: #747474;
  color: white;
  cursor: pointer;
}

div.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker-modify {
  50% {
    background-color: rgba(255, 187, 51, .3);
  }
}

#arrorBtn {
  background-color: #E9E9E9;
  border-radius: 50px;
}

#arrorBtn:hover {
  background-color: #747474;
  color: white;
  cursor: pointer;
}

#arrorBtn .material-icons {
  vertical-align: bottom;
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding: .375rem .75rem;
  margin: 0;
  z-index: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

strong {
  font-weight: bolder;
}

.amendment-columns {
  column-count: 1;
}

.rainbow {
  background: linear-gradient(124deg, #ff2400, #e8b71d, #1de840, #1ddde8);
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 5s ease infinite;
  -o-animation: rainbow 5s ease infinite;
  animation: rainbow 5s ease infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

.bg-loading {
  background: linear-gradient(124deg, #ffffff, #e7e7e7, #ffffff);
  background-size: 800% 800%;

  -webkit-animation: bg-loading 1s linear infinite;
  -o-animation: bg-loading 1s linear infinite;
  animation: bg-loading 1s linear infinite;
}

@keyframes bg-loading {
  0% {
    background-position-x: 82%
  }

  100% {
    background-position-x: 19%
  }
}

.hover-clickable:hover {
  cursor: pointer;
}

.child-no-margin p {
  margin: .25rem 0rem;
}

#aud-display>div {
  padding-bottom: .5rem !important;
}

.icon-btn {
  border-radius: 50px;
  padding: .25rem;
}

.icon-btn:hover {
  background-color: #e9ecef;
}

.icon-btn.disabled {
  color: #6c757d;
}

.icon-btn .material-icons {
  vertical-align: bottom;
}

@media (max-width: 768px) {
  #director-delegate.card-columns {
    columns: 1;
  }
}

.delegate-card {
  background-color: #E9E9E9;
}

.committee-foot {
  min-height: 60px;
  padding: .5rem .75rem;
}

.committee-foot h1,
.committee-foot h2,
.committee-foot h3,
.committee-foot h4,
.committee-foot h5,
.committee-foot h6 {
  margin-bottom: 0px;
}

.committee-foot>div {
  align-self: center;
}

.nav-item-padding {
  padding-left: .1rem;
  padding-right: .1rem;
}

.btn-xsm {
  padding: 0.25rem 0.8rem;
  font-size: 0.64rem;
}

.badge-group .badge:first-child {
  border-radius: 0.125rem 0 0 0.125rem;
}

.badge-group .badge {
  border-radius: 0;
}

.badge-group .badge:last-child {
  border-radius: 0 0.125rem 0.125rem 0;
}

.badge-grey {
  color: #fff;
  background-color: #9e9e9e;
}

.dropdown-item .material-icons {
  vertical-align: top;
  padding-right: 15px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}